import { Pipe, PipeTransform } from '@angular/core';
import {Player} from '../../../models/player.model';

@Pipe({
  name: 'turnOrderPipe'
})
export class TurnOrderPipePipe implements PipeTransform {

  transform(value: Player[], args?: any): any {
    return value.sort((a, b) => {
      return a.orderThisGen - b.orderThisGen;
    });
  }

}
