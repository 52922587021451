import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Lobby} from '../../models/lobby.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class LobbyService {

  constructor(private http: HttpClient) { }

  getGameLobbies(): Observable<Lobby[]> {
    return this.http.get<Lobby[]>('/api/game-lobby');
  }

  getGameLobby(LobbyId): Observable<Lobby> {
    return this.http.get<Lobby>('/api/game-lobby/' + LobbyId);
  }

  joinGameLobby(LobbyId: string, password?: string): Observable<Lobby> {
    return this.http.post<Lobby>('/api/game-lobby/' + LobbyId + '/join', {password}, httpOptions);
  }

  saveGameLobby(LobbyId: string, payload: any): Observable<Lobby> {
    return this.http.post<Lobby>('/api/game-lobby/' + LobbyId + '', {payload}, httpOptions);
  }

  newGameLobby(name: string, password ?: string): Observable<Lobby> {
    return this.http.post<Lobby>('/api/game-lobby/new', {name, password}, httpOptions);
  }

  leaveGameLobby(LobbyId): Observable<Lobby> {
    return this.http.post<Lobby>('/api/game-lobby/' + LobbyId + '/leave', {}, httpOptions);
  }

  startGameLobby(LobbyId: string, mapName?: string): Observable<{_id: string}> {
    return this.http.post<{_id: string}>('/api/game-lobby/' + LobbyId + '/start', {mapName}, httpOptions);
  }

}
