import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GameListing} from '../../models/game-listing.model';

@Injectable({
  providedIn: 'root'
})
export class BrowseService {

  constructor(private http: HttpClient) { }

  getActiveGames(): Observable<GameListing[]> {
    return this.http.get<GameListing[]>('/api/active-games');
  }

  getCompletedGames(): Observable<GameListing[]> {
    return this.http.get<GameListing[]>('/api/active-games/complete');
  }

  getWeeklyScoreboard(): Observable<GameListing[]> {
    return this.http.get<GameListing[]>('/api/scoreboard/weekly');
  }
}
