import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  message = 'Verifying email. Please wait.';
  loading = false;
  showButton = false;
  email = '';

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private router: Router,
              private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      if (params['key']) {
        this.loading = true;
        this.userService.verify(params['key']).subscribe(() => {
          this.loading = false;
          this.message = 'Redirecting...';
          this.toastr.info('Email verified. You may now log in.');
          this.router.navigate(['login']);
        }, () => {
          this.loading = false;
          this.showButton = true;
          this.message = 'There was an error while trying to verify your email account.';
        });
      } else {
        this.showButton = true;
        this.message = 'There was an error while trying to verify your email account.';
      }
    });
  }

  ngOnInit() {
  }

}
