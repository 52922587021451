import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'cardImage'})
export class CardImagePipe implements PipeTransform {
  transform (name: string): string {
    return 'https://s3.amazonaws.com/play-tfm-bucket/cards/' +
      name.toLowerCase().replace(/[\s]/g, '_')
        .replace(/'/g, '').replace(/\./, '') + '.jpg';
  }
}
