import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-choose-card-effect',
  templateUrl: './choose-card-effect.component.html',
  styleUrls: ['./choose-card-effect.component.scss']
})
export class ChooseCardEffectComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

}
