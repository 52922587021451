import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {

  constructor (private toastr: ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err: any) => {
      if (err instanceof  HttpErrorResponse) {
        if (err.status === 400) {
          if (err.error.message) {
            this.toastr.error(err.error.message);
          }
        }
      }
      return observableThrowError(err);
    }));
  }
}
