import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bug-report-modal',
  templateUrl: './bug-report-modal.component.html',
  styleUrls: ['./bug-report-modal.component.scss']
})
export class BugReportModalComponent implements OnInit {
  report: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
    this.report = '';
  }

}
