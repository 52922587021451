import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Card} from '../../../../../models/card.model';
import {TapCardModalComponent} from '../../../page-components/current-player-board/tap-card-modal/tap-card-modal.component';

@Component({
  selector: 'app-viron-tap',
  templateUrl: './viron-tap.component.html',
  styleUrls: ['./viron-tap.component.scss']
})
export class VironTapComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

  tapCard(card: Card) {
    if (card.tapOptions.length === 1) {
      this.submitDecision('TAP_CARD',
        {card: card.name, tapOption: card.tapOptions[0].tapOption},
        {card: card, tapData: card.tapOptions[0]});
    } else {
      const modal = this.modalService.open(TapCardModalComponent);
      modal.componentInstance.game = this.game;
      modal.componentInstance.card = card;
      modal.result.then((tapData) => {
        this.submitDecision('TAP_CARD',
          {card: card.name, tapOption: tapData.tapOption},
          {card: card, tapData: tapData});
      });
    }
  }
}
