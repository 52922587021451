import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Decision} from '../../decision.component';
import {Card} from '../../../../../models/card.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {GameService} from '../../../../../services/game/game.service';
import {Game} from '../../../../../models/game.model';

@Component({
  selector: 'app-starting-hand',
  templateUrl: './starting-hand.component.html',
  styleUrls: ['./starting-hand.component.scss']
})
export class StartingHandComponent extends Decision implements OnChanges {
  selectedCards: Card[];

  constructor(modalService: NgbModal,
              private router: Router,
              private toastr: ToastrService,
              private gameService: GameService) {
    super(modalService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedCards = [];
  }

  mapSelectedCards (): string[] {
    return this.selectedCards.map(c => c.name);
  }

  resetGame () {
    this.gameService.resetGame(this.game._id).subscribe((msg) => {
      this.toastr.info(msg.message);
      this.router.navigate(['/game', this.game._id], {queryParams: {t: Date.now()}});
    });
  }
}
