import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Game} from '../../models/game.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private http: HttpClient) { }

  getGame(GameId: string): Observable<Game> {
    return this.http.get<Game>('/api/game/' + GameId);
  }

  submitDecision (GameId: string, payload: any): Observable<any> {
    return this.http.post<any>('/api/game/' + GameId, payload, httpOptions);
  }

  checkRefresh (GameId: string): Observable<{_id: string, refreshCounter: number}> {
    return this.http.get<{_id: string, refreshCounter: number}>('/api/game/' + GameId + '/refresh');
  }

  bugReport (report: string, GameId: string): Observable<any> {
    return this.http.post<any>('/api/bug', {report, GameId}, httpOptions);
  }

  getCheats(GameId: string): Observable<{cards: string[], corps: string[]}> {
    return this.http.get<{cards: string[], corps: string[]}>('/api/cheats/' + GameId);
  }

  submitCheat(GameId: string, payload: any): Observable<any> {
    return this.http.post('/api/cheats/' + GameId, payload, httpOptions);
  }

  resetGame (GameId: string): Observable<any> {
    return this.http.post<any>('/api/game/' + GameId + '/reset', {});
  }
}
