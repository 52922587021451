import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decisions/decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Card} from '../../../../models/card.model';
import {TapCardModalComponent} from './tap-card-modal/tap-card-modal.component';

@Component({
  selector: 'app-current-player-board',
  templateUrl: './current-player-board.component.html',
  styleUrls: ['./current-player-board.component.scss']
})
export class CurrentPlayerBoardComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

  tapCard(card: Card) {
    if (this.game.currentPlayerDecision !== 'MAIN_ACTION') {
      return;
    }
    if (card.tapOptions.length === 1) {
      this.submitDecision('TAP_CARD',
        {card: card.name, tapOption: card.tapOptions[0].tapOption},
        {card: card, tapData: card.tapOptions[0]});
    } else {
      const modal = this.modalService.open(TapCardModalComponent);
      modal.componentInstance.game = this.game;
      modal.componentInstance.card = card;
      modal.result.then((tapData) => {
        this.submitDecision('TAP_CARD',
          {card: card.name, tapOption: tapData.tapOption},
          {card: card, tapData: tapData});
      });
    }
  }

}
