import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LobbyService} from '../../services/lobby/lobby.service';
import {Lobby} from '../../models/lobby.model';

@Injectable()
export class GetLobbyResolver implements Resolve<Observable<Lobby>> {

  constructor(private lobbyService: LobbyService) { }

  resolve (route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    return this.lobbyService.getGameLobby(route.params['id']);
  }
}
