import {Component, Input, OnInit} from '@angular/core';
import {Resources} from '../../../../models/resources.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Game} from '../../../../models/game.model';

@Component({
  selector: 'app-cheats',
  templateUrl: './cheat-modal.component.html',
  styleUrls: ['./cheat-modal.component.scss']
})
export class CheatModalComponent implements OnInit {
  resources: Resources;
  production: Resources;
  card: string;
  corp: string;
  target: string;
  @Input() state: Game;
  @Input() cards: string[];
  @Input() corps: string[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.resources = {} as Resources;
    this.production = {} as Resources;
  }

}
