import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tile-placement-decision',
  templateUrl: './tile-placement-decision.component.html',
  styleUrls: ['./tile-placement-decision.component.scss']
})
export class TilePlacementDecisionComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

}
