import {Component, Input, OnChanges} from '@angular/core';
import {Game} from '../../../../models/game.model';

@Component({
  selector: 'app-game-status',
  templateUrl: './game-status.component.html',
  styleUrls: ['./game-status.component.scss']
})
export class GameStatusComponent implements OnChanges {
  @Input() game: Game;
  oceans: string[];
  ocean_labels: any[];
  oxygen: string[];
  oxygen_labels: any[];
  temperature: string[];
  temperature_labels: any[];
  venus: string[];
  venus_labels: any[];

  constructor() { }

  ngOnChanges(): void {
    this.oceans = [];
    this.ocean_labels = [];
    this.oxygen = [];
    this.oxygen_labels = [];
    this.temperature = [];
    this.temperature_labels = [];
    this.venus = [];
    this.venus_labels = [];
    for (let i = 0; i <= 9; i++) { // Using <= instead of < on purpose. Makes first slot full
      this.ocean_labels.push({
        label: '' + i
      });
      if ((9 - i) >= this.game.oceansRemaining) {
        this.oceans.push('filled');
      } else {
        this.oceans.push('');
      }
    }

    for (let i = 0; i <= 14; i++) {
      const oLabel = {
        label: '' + i + '%'
      } as any;
      if (i === 8 && i > this.game.oxygenSteps) {
        oLabel.className = 'temp_bump';
        oLabel.tooltip = 'At 8% oxygen, increase the temperature 1 step';
      }
      this.oxygen_labels.push(oLabel);
      if (i <= this.game.oxygenSteps) {
        this.oxygen.push('filled');
      } else {
        this.oxygen.push('');
      }
    }

    for (let i = 0; i <= 15; i++) {
      const vLabel = {
        label: '' + (i * 2)
      } as any;
      if (i === 4 && i > this.game.venusSteps) {
        vLabel.className = 'venus_bump';
        vLabel.tooltip = 'At 8% Venus, draw 1 card';
      }
      if (i === 8 && i > this.game.venusSteps) {
        vLabel.className = 'venus_bump';
        vLabel.tooltip = 'At 16% Venus, gain 1 TR';
      }
      this.venus_labels.push(vLabel);
      if (i <= this.game.venusSteps) {
        this.venus.push('filled');
      } else {
        this.venus.push('');
      }
    }

    for (let i = 0; i <= 19; i++) {
      const temp = -30 + (i * 2);
      const tempLabel = {
        label: '' + temp
      } as any;
      if ((i === 3 || i === 5) && i > this.game.temperatureSteps) {
        tempLabel.className = 'heat_bump';
        tempLabel.tooltip = 'At ' + temp + ', increase your heat production by 1 step';
      }
      if (i === 15 && i > this.game.temperatureSteps) {
        tempLabel.className = 'ocean_bump';
        tempLabel.tooltip = 'At ' + temp + ', place an ocean tile';
      }
      this.temperature_labels.push(tempLabel);

      if (i <= this.game.temperatureSteps) {
        this.temperature.push('filled');
      } else {
        this.temperature.push('');
      }
    }
  }
}
