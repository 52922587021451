import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() name: string;
  @Input() isCorporation = false;
  @Input() tooltip?: string;
  @Input() disabled?: boolean;
  @Input() hasBeenTapped?: boolean;
  @Input() counters?: number;
  @Input() counterType?: string;
  @Input() tapOptions?: any[];

  constructor() { }

  ngOnInit() {
  }

}
