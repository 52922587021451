import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Lobby} from '../../models/lobby.model';
import {GameListing} from '../../models/game-listing.model';
import {LobbyService} from '../../services/lobby/lobby.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewLobbyModalComponent} from './new-lobby-modal/new-lobby-modal.component';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user/user.service';
import {Player} from '../../models/player.model';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
  lobbies: Lobby[];
  games: GameListing[];
  user: any;

  constructor(private actr: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private modalService: NgbModal,
              private userService: UserService,
              private lobbyService: LobbyService) {
    this.actr.data.subscribe(data => {
      this.user = this.userService.user;
      this.lobbies = data['lobbies'];
      this.games = data['games'];
    });
  }

  ngOnInit() {
  }

  createLobby () {
    this.modalService.open(NewLobbyModalComponent, {}).result.then((result) => {
      if (result) {
        this.lobbyService.newGameLobby(result.name, result.password).subscribe(data => {
          this.toastr.info('New game lobby created');
          this.router.navigate(['/lobby', data._id]);
        });
      }
    });
  }
}
