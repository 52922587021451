import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchase-card-decision',
  templateUrl: './purchase-card-decision.component.html',
  styleUrls: ['./purchase-card-decision.component.scss']
})
export class PurchaseCardDecisionComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

}
