import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tile} from '../../../../../models/tile.model';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent implements OnInit {
  @Input() tile: Tile;
  @Output() tileClicked = new EventEmitter<Tile>();
  bonuses: string[];

  constructor() { }

  ngOnInit() {
    this.bonuses = [];
    if (this.tile.resources.steel) {
      for (let i = 0; i < this.tile.resources.steel; i++) {
        this.bonuses.push('steel');
      }
    }
    if (this.tile.resources.titanium) {
      for (let i = 0; i < this.tile.resources.titanium; i++) {
        this.bonuses.push('titanium');
      }
    }
    if (this.tile.resources.plant) {
      for (let i = 0; i < this.tile.resources.plant; i++) {
        this.bonuses.push('plant');
      }
    }
    if (this.tile.resources.heat) {
      for (let i = 0; i < this.tile.resources.heat; i++) {
        this.bonuses.push('heat');
      }
    }
    if (this.tile.cards) {
      for (let i = 0; i < this.tile.cards; i++) {
        this.bonuses.push('card');
      }
    }
  }

  onTileClicked () {
    this.tileClicked.emit(this.tile);
  }

}
