import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  email1: string;
  email2: string;
  password1: string;
  password2: string;
  playername: string;
  showRegistration = true;
  loading = false;

  constructor(private userService: UserService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  register() {
    if (this.email1 !== this.email2) {
      this.toastr.error('Email addresses must match');
      return;
    }
    if (this.password1 !== this.password2) {
      this.toastr.error('Passwords must match');
    }
    this.loading = true;
    this.userService.register(this.email1, this.password1, this.playername).subscribe(() => {
      this.loading = false;
      this.toastr.success('Your registration is now pending. Check your email to activate your account.');
      this.showRegistration = false;
    }, () => {
      this.loading = false;
    });
  }
}
