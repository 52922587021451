import {Component, Input, OnInit} from '@angular/core';
import {Game} from '../../../../models/game.model';

@Component({
  selector: 'app-game-end-summary',
  templateUrl: './game-end-summary.component.html',
  styleUrls: ['./game-end-summary.component.scss']
})
export class GameEndSummaryComponent implements OnInit {
  @Input() game: Game;

  constructor() { }

  ngOnInit() {
  }

}
