import { Component, OnInit } from '@angular/core';
import {GameListing} from '../../models/game-listing.model';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  games: GameListing[];

  constructor(private actr: ActivatedRoute) {
    this.actr.data.subscribe(data => {
      this.games = data['games'];
    });
  }

  ngOnInit() {
  }

}
