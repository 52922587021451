import { Pipe, PipeTransform } from '@angular/core';
import {GameListing} from '../../models/game-listing.model';

@Pipe({
  name: 'yourTurnPipe'
})
export class YourTurnPipePipe implements PipeTransform {

  transform(games: GameListing[], UserId: string, showYouTurn: boolean): any {
    return games.filter(game => {
      return (game.currentDecisions.map(dec => dec.UserId).indexOf(UserId) >= 0) === showYouTurn;
    });
  }

}
