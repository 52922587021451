import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  showForgotPassword = true;
  email: string;
  loading = false;

  constructor(private userService: UserService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  submit () {
    if (!!this.email) {
      this.loading = true;
      this.userService.forgot(this.email).subscribe(() => {
        this.loading = false;
        this.showForgotPassword = false;
        this.toastr.info('Password reset email sent.');
      }, () => {
        this.loading = false;
      });
    }
  }

}
