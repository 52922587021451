import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-solar',
  templateUrl: './solar.component.html',
  styleUrls: ['./solar.component.scss']
})
export class SolarComponent extends Decision implements OnInit {

  constructor(modalService: NgbModal) {
    super(modalService);
  }

  ngOnInit() {
  }

}
