import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  loading = false;

  constructor(private userService: UserService,
              private router: Router) { }

  ngOnInit() {
  }

  login () {
    this.loading = true;
    this.userService.logIn(this.email, this.password).subscribe(() => {
      this.loading = false;
      this.router.navigate(['/browse']);
    }, () => {
      this.loading = false;
    });
  }
}
