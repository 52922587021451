import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {NavigationStart, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BugReportModalComponent} from '../../pages/game/modals/bug-report-modal/bug-report-modal.component';
import {BugService} from '../../services/bug/bug.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  show = false;
  isLoggedIn = false;
  showBugReport = false;
  GameId: string;

  constructor(private userService: UserService,
              private toastr: ToastrService,
              private modal: NgbModal,
              private bug: BugService,
              private router: Router) {
    this.isLoggedIn = this.userService.isLoggedIn();
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.isLoggedIn = this.userService.isLoggedIn();
          this.showBugReport = (event.url.indexOf('/game/') >= 0);
        }
      });
  }

  ngOnInit() {
  }

  logout() {
    this.userService.logout();
    this.toastr.info('You have successfully logged out');
    this.router.navigate(['/', 'login']);
  }

  openBugReport() {
    this.modal.open(BugReportModalComponent, {size: 'lg'}).result.then((report) => {
      if (report) {
        const GameId = window.location.href.match(/\/([a-zA-Z1234567890]+)[\?#]?[^\/]+$/)[1];
        this.bug.submitBugReport(GameId, report).subscribe(() => {
          this.toastr.error('Bug report submitted');
        });
      }
    });
  }
}
