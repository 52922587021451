import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Game} from '../../../../models/game.model';

const rowStarts = [2, 2, 1, 1, 0, 1, 1, 2, 2];
const rowEnds = [6, 7, 7, 8, 8, 8, 7, 7, 6];

@Component({
  selector: 'app-confirm-decision',
  templateUrl: './confirm-decision.component.html',
  styleUrls: ['./confirm-decision.component.scss']
})
export class ConfirmDecisionComponent implements OnInit {
  @Input() ActionId: string;
  @Input() ActionData: any;
  @Input() game: Game;
  @Input() UIData?: any;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }
  
  getTopBottomLeftRightString () {
    let str = '';
    if (this.ActionData.y === 0) {
      str += 'on the top row';
    } else if (this.ActionData.y === 8) {
      str += 'on the bottom row' ;
    } else if (this.ActionData.y === 4) {
      str += 'on the middle row';
    } else if (this.ActionData.y === 1) {
      str += 'on the 2nd row';
    } else if (this.ActionData.y === 2) {
      str += 'on the 3rd row';
    } else {
      str += 'on the ' + (this.ActionData.y + 1) + 'th row';
    }
    str += ', ';
    if (this.ActionData.x === rowStarts[this.ActionData.y]) {
      str += 'left-most';
    } else if (this.ActionData.x === rowEnds[this.ActionData.y]) {
      str += 'right-most';
    } else {
      const leftDist = this.ActionData.x - rowStarts[this.ActionData.y] + 1;
      const rightDist = rowEnds[this.ActionData.y] - this.ActionData.x + 1;
      if (leftDist <= rightDist) {
        str += leftDist + ' spaces from the left';
      } else {
        str += rightDist + ' spaces from the right';
      }
    }
    return str;
  }

  getAttackString () {
    const resources = this.game.currentPlayerDecisionData.resources;
    const production = this.game.currentPlayerDecisionData.production;
    let str = '';
    if (resources) {
      for (let k in resources) {
        if (resources.hasOwnProperty(k)) {
          str += '-' + Math.abs(resources[k]) + ' ' + k + '';
          if (k === 'plant') {
            str += 's';
          }
        }
      }
    }
    if (production) {
      for (let k in production) {
        if (production.hasOwnProperty(k)) {
          str += '-' + Math.abs(production[k]) + ' ' + k + ' production';
          if (k === 'plant') {
            str += 's';
          }
        }
      }
    }
    return str;
  }
}
