import {Component, Input, OnChanges} from '@angular/core';
import {Player} from '../../../../models/player.model';
import {Game} from '../../../../models/game.model';

@Component({
  selector: 'app-player-summary',
  templateUrl: './player-summary.component.html',
  styleUrls: ['./player-summary.component.scss']
})
export class PlayerSummaryComponent implements OnChanges {
  @Input() player: Player;
  @Input() game: Game;
  @Input() showSelectedIcon ?= true;
  @Input() selectedPlayer: Player;
  playerIsActive = false;

  constructor() { }

  ngOnChanges() {
    this.playerIsActive = this.game.decisionStack.map(d => d.UserId).indexOf(this.player.UserId) >= 0;
  }

  onPlayerSelected () {
    this.selectedPlayer = this.player;
  }
}
