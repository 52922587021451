import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-log-modal',
  templateUrl: './view-log-modal.component.html',
  styleUrls: ['./view-log-modal.component.scss']
})
export class ViewLogModalComponent implements OnInit {
  @Input() log: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}
