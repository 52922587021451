import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Game} from '../../../../../models/game.model';
import {Card} from '../../../../../models/card.model';

@Component({
  selector: 'app-tap-card-modal',
  templateUrl: './tap-card-modal.component.html',
  styleUrls: ['./tap-card-modal.component.scss']
})
export class TapCardModalComponent implements OnInit {
  @Input() game: Game;
  @Input() card: Card;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}
