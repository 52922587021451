import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Decision} from '../../decisions/decision.component';
import {Card} from '../../../../models/card.model';

@Component({
  selector: 'app-current-player-hand',
  templateUrl: './current-player-hand.component.html',
  styleUrls: ['./current-player-hand.component.scss']
})
export class CurrentPlayerHandComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

  playCard(card: Card) {
    if (this.game.currentPlayerDecision === 'MAIN_ACTION') {
      this.submitDecision('PLAY_CARD', {card: card.name}, {card: card});
    }
  }

}
