import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-choose-attack-target',
  templateUrl: './choose-attack-target.component.html',
  styleUrls: ['./choose-attack-target.component.scss']
})
export class ChooseAttackTargetComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }


  getAttackString () {
    const resources = this.game.currentPlayerDecisionData.resources;
    const production = this.game.currentPlayerDecisionData.production;
    let str = '';
    if (resources) {
      for (let k in resources) {
        if (resources.hasOwnProperty(k)) {
          str += '-' + Math.abs(resources[k]) + ' ' + k + '';
          if (k === 'plant') {
            str += 's';
          }
        }
      }
    }
    if (production) {
      for (let k in production) {
        if (production.hasOwnProperty(k)) {
          str += '-' + Math.abs(production[k]) + ' ' + k + ' production';
          if (k === 'plant') {
            str += 's';
          }
        }
      }
    }
    return str;
  }
}
