import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tileName'
})
export class TileNamePipe implements PipeTransform {

  transform(name: string): string {
    switch (name) {
      case 'ARTIFICIAL_LAKE':
        return 'Ocean (Artifical Lake)';
      case 'CAPITAL':
        return 'Capital (City)';
      case 'COMMERCIAL_DISTRICT':
        return 'Commercial District';
      case 'ECOLOGICAL_ZONE':
        return 'Ecological Zone';
      case 'FLOOD_OCEAN':
        return 'Ocean';
      case 'GANYMEDE_COLONY':
        return 'Ganymede Colony';
      case 'INDUSTRIAL_CENTER':
        return 'Industrial Center';
      case 'LAND_CLAIM':
        return 'Land Claim';
      case 'LAVA_FLOWS':
        return 'Lava Flows';
      case 'MINING_AREA':
        return 'Mining Area';
      case 'MINING_RIGHTS':
        return 'Mining Rights';
      case 'MOHOLE_AREA':
        return 'Mohole Area';
      case 'NATURAL_PRESERVE':
        return 'Natural Preserve';
      case 'NOCTIS_CITY':
        return 'Noctis City';
      case 'NUCLEAR_ZONE':
        return 'Nuclear Zone';
      case 'OCEAN_GREENERY':
        return 'Greenery (Ocean)';
      case 'PHOBOS_SPACE_HAVEN':
        return 'Phobos Space Haven';
      case 'SOLAR_OCEAN':
        return 'Solar Ocean';
      case 'RESEARCH_OUTPOST':
        return 'Research Outpost (City)';
      case 'RESTRICTED_AREA':
        return 'Restricted Area';
      case 'STANDARD_CITY':
        return 'City';
      case 'STANDARD_GREENERY':
        return 'Greenery';
      case 'STANDARD_OCEAN':
        return 'Ocean';
      case 'URBANIZED_AREA':
        return 'Urbanized Area (City)';
    }
  }
}

@Pipe({
  name: 'tileRequirements'
})
export class TileRequirementsPipe implements PipeTransform {

  transform(name: string): string {
    switch (name) {
      case 'ARTIFICIAL_LAKE':
        return 'Must be placed on a non-Ocean area';
      case 'CAPITAL':
        return '';
      case 'COMMERCIAL_DISTRICT':
        return '';
      case 'ECOLOGICAL_ZONE':
        return 'Must be placed adjacent to a greenery';
      case 'FLOOD_OCEAN':
        return 'Must be placed on a slot reserved for oceans';
      case 'GANYMEDE_COLONY':
        return 'Must be placed on the Ganymede Colony area';
      case 'INDUSTRIAL_CENTER':
        return 'Must be placed adjacent to a city';
      case 'LAND_CLAIM':
        return '';
      case 'LAVA_FLOWS':
        return 'Must be placed on a mountain tile.';
      case 'MINING_AREA':
        return 'Must be placed adjacent to one of your tiles';
      case 'MINING_RIGHTS':
        return '';
      case 'MOHOLE_AREA':
        return 'Must be placed on an area reserved for ocean';
      case 'NATURAL_PRESERVE':
        return 'Must be placed next to no other tile';
      case 'NOCTIS_CITY':
        return 'Must be placed on the Noctis City location.';
      case 'NUCLEAR_ZONE':
        return '';
      case 'OCEAN_GREENERY':
        return 'Must be placed on an area reserved for ocean';
      case 'PHOBOS_SPACE_HAVEN':
        return 'Must be placed on the Phobos Space Haven area';
      case 'RESEARCH_OUTPOST':
        return 'Must be placed next to no other tile';
      case 'RESTRICTED_AREA':
        return '';
      case 'STANDARD_CITY':
        return 'Must not be placed adjacent to another city';
      case 'STANDARD_GREENERY':
        return 'Must be placed adjacent to one of your tiles if possible';
      case 'STANDARD_OCEAN':
        return 'Must be placed on an area reserved for ocean';
      case 'URBANIZED_AREA':
        return 'Must be placed adjacent to two city tiles';
    }
  }
}
