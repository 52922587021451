import {GameService} from '../../services/game/game.service';
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Game} from '../../models/game.model';

@Injectable()
export class GetGameResolver implements Resolve<Observable<Game>> {

  constructor(private gameService: GameService) { }

  resolve (route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    return this.gameService.getGame(route.params['id']);
  }
}
