import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Card} from '../../../../models/card.model';

@Component({
  selector: 'app-player-hand',
  templateUrl: './player-hand.component.html',
  styleUrls: ['./player-hand.component.scss']
})
export class PlayerHandComponent implements OnInit {
  @Input() cards: Card[];
  @Output() cardSelected = new EventEmitter<Card>();

  constructor() { }

  ngOnInit() {
  }

  onCardClicked(card: Card) {
    this.cardSelected.emit(card);
  }
}
