import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  loading = false;
  password1: string;
  password2: string;
  key: string;

  constructor(private router: Router,
              private actr: ActivatedRoute,
              private toastr: ToastrService,
              private userService: UserService) {
    this.actr.queryParams.subscribe(params => {
      this.key = params['key'];
    });
  }

  ngOnInit() {
  }

  submit() {
    if (this.password1 && this.password1 === this.password2) {
      this.loading = true;
      this.userService.resetPassword(this.key, this.password1).subscribe(() => {
        this.loading = false;
        this.toastr.success('Password reset successful. You may now log in with the new password.');
        this.router.navigate(['login']);
      }, () => {
        this.loading = false;
      });
    } else {
      this.toastr.warning('Passwords must match');
    }
  }
}
