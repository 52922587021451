import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {UserNotLoggedInGuard} from './utilities/guards/user-not-logged-in.guard';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {BrowseComponent} from './pages/browse/browse.component';
import {UserLoggedInGuard} from './utilities/guards/user-logged-in.guard';
import {LobbyComponent} from './pages/lobby/lobby.component';
import {GameComponent} from './pages/game/game.component';
import {GetGameLobbiesResolver} from './utilities/resolvers/get-game-lobbies.resolver';
import {GetActiveGamesResolver} from './utilities/resolvers/get-active-games.resolver';
import {GetLobbyResolver} from './utilities/resolvers/get-lobby.resolver';
import {GetGameResolver} from './utilities/resolvers/get-game.resolver';
import {VerifyComponent} from './pages/verify/verify.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {PasswordResetComponent} from './pages/password-reset/password-reset.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {GetCompletedGamesResolver} from './utilities/resolvers/get-completed-games.resolver';
import { ScoreboardComponent } from './pages/scoreboard/scoreboard.component';
import { WeeklyScoreboardResolver } from './utilities/resolvers/weekly-scoreboard.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [UserNotLoggedInGuard]
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [UserNotLoggedInGuard]
  }, {
    path: 'register',
    component: RegisterComponent,
    canActivate: [UserNotLoggedInGuard]
  }, {
    path: 'verify',
    component: VerifyComponent
  }, {
    path: 'forgot',
    component: ForgotPasswordComponent
  }, {
    path: 'password-reset',
    component: PasswordResetComponent
  }, {
    path: 'browse',
    component: BrowseComponent,
    canActivate: [UserLoggedInGuard],
    resolve: {
      lobbies: GetGameLobbiesResolver,
      games: GetActiveGamesResolver
    }
  }, {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [UserLoggedInGuard],
    resolve: {
      games: GetCompletedGamesResolver
    }
  }, {
    path: 'scoreboard',
    component: ScoreboardComponent,
    canActivate: [UserLoggedInGuard],
    resolve: {
      weeklyScoreboard: WeeklyScoreboardResolver
    }
  }, {
    path: 'lobby/:id',
    component: LobbyComponent,
    canActivate: [UserLoggedInGuard],
    resolve: {
      lobby: GetLobbyResolver
    }
  }, {
    path: 'game/:id',
    component: GameComponent,
    canActivate: [UserLoggedInGuard],
    resolve: {
      game: GetGameResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
