import {Component, Input, OnInit} from '@angular/core';
import {Game} from '../../../../models/game.model';
import {Tile} from '../../../../models/tile.model';
import {Decision} from '../../decisions/decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-game-board',
  templateUrl: './game-board.component.html',
  styleUrls: ['./game-board.component.scss']
})
export class GameBoardComponent extends Decision implements OnInit {
  @Input() game: Game;

  constructor(modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

  tileClicked (tile: Tile) {
    if (this.game.currentPlayerDecision === 'PLACE_TILE') {
      this.submitDecision('PLACE_TILE', {x: tile.x, y: tile.y}, {tile: this.game.currentPlayerDecisionData.tile});
    }
  }
}

