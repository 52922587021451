import {Pipe, PipeTransform} from '@angular/core';
import {Tile} from '../../../models/tile.model';

@Pipe({name: 'tilesOnMars'})
export class TilesOnMarsPipe implements PipeTransform {
  transform (tiles: Tile[]): Tile[] {
    return tiles.filter(t => t.isOnMars);
  }
}

@Pipe({name: 'tilesNotOnMars'})
export class TilesNotOnMarsPipe implements PipeTransform {
  transform (tiles: Tile[]): Tile[] {
    return tiles.filter(t => !t.isOnMars);
  }
}
