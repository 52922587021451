import { Component, OnInit } from '@angular/core';
import {GameListing} from '../../models/game-listing.model';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-scoreboard',
  templateUrl: './scoreboard.component.html',
  styleUrls: ['./scoreboard.component.scss']
})
export class ScoreboardComponent implements OnInit {
  weeklyScoreboard: GameListing[];

  constructor(private actr: ActivatedRoute) {
    this.actr.data.subscribe(data => {
      this.weeklyScoreboard = data['weeklyScoreboard'];
    });
  }

  ngOnInit() {
  }
}
