import {Component, OnChanges} from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Card} from '../../../../../models/card.model';

@Component({
  selector: 'app-draft-cards',
  templateUrl: './draft-cards.component.html',
  styleUrls: ['./draft-cards.component.scss']
})
export class DraftCardsComponent extends Decision implements OnChanges {
  selectedCards: Card[];

  constructor(modalService: NgbModal) {
    super(modalService);
  }

  ngOnChanges() {
    this.selectedCards = [];
  }

  mapCards(): string[] {
    return this.selectedCards.map(c => c.name);
  }
}
