import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {BrowseService} from '../../services/browse/browse.service';
import {Injectable} from '@angular/core';
import {GameListing} from '../../models/game-listing.model';

@Injectable()
export class GetActiveGamesResolver implements Resolve<Observable<GameListing[]>> {

  constructor(private browseService: BrowseService) { }

  resolve (route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    return this.browseService.getActiveGames();
  }
}
