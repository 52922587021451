import {Component, Input, OnInit} from '@angular/core';
import {Game} from '../../../../models/game.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-award-milestone-modal',
  templateUrl: './award-milestone-modal.component.html',
  styleUrls: ['./award-milestone-modal.component.scss']
})
export class AwardMilestoneModalComponent implements OnInit {
  @Input() type: string;
  @Input() game: Game;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}
