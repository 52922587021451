import { Pipe, PipeTransform } from '@angular/core';
import {Card} from '../../../models/card.model';

@Pipe({
  name: 'cardBooleanFilter'
})
export class CardBooleanFilterPipe implements PipeTransform {

  transform(cards: Card[], field: string, value: any): Card[] {
    return cards.filter(c => !!c[field] === value);
  }
}
