import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: any;

  constructor(private http: HttpClient,
              private toastr: ToastrService) {
    const user = localStorage.getItem('currentUser');
    if (!!user) {
      this.user = JSON.parse(user) as any;
      console.log(this.user);
    }
  }

  logIn (email: string, password: string): Observable<any> {
    return this.http.post('/api/user/login', {email, password}, httpOptions).pipe(
      map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.toastr.success('You have successfully logged in');
        this.user = user;
        return user;
      })
    );
  }

  logout () {
    localStorage.removeItem('currentUser');
    this.user = null;
  }

  isLoggedIn (): boolean {
    return !!localStorage.getItem('currentUser');
  }

  register (email: string, password: string, username: string): Observable<any> {
    return this.http.post('/api/user/register', {email, password, username});
  }

  verify(key: string): Observable<any> {
    return this.http.post<any>('/api/user/verify', { key }, httpOptions);
  }

  forgot (email: string): Observable<any> {
    return this.http.post<any>('/api/user/forgot', {email}, httpOptions);
  }

  resetPassword (key: string, password: string): Observable<any> {
    return this.http.post<any>('/api/user/reset', {key, password}, httpOptions);
  }
}
