import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LobbyService} from '../../services/lobby/lobby.service';
import {Lobby} from '../../models/lobby.model';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LobbyPasswordModalComponent} from './lobby-password-modal/lobby-password-modal.component';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent implements OnInit {
  lobby: Lobby;
  isLobbyCreator: boolean;
  isInLobby: boolean;
  loading = false;

  constructor(private actr: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private modalService: NgbModal,
              private userService: UserService,
              private lobbyService: LobbyService) {
    this.actr.data.subscribe(data => {
      this.lobby = data['lobby'];
      this.isLobbyCreator = (this.userService.user && this.lobby.creator.UserId === this.userService.user._id);
      this.isInLobby = (this.userService.user && this.lobby.players.map(p => p.UserId).indexOf(this.userService.user._id) >= 0);
    });
  }

  joinLobby () {
    if (this.lobby.isPasswordProtected && !this.isLobbyCreator) {
      this.modalService.open(LobbyPasswordModalComponent).result.then(password => {
        this.submitJoinRequest(password);
      });
    } else {
      this.submitJoinRequest();
    }
  }

  submitJoinRequest (password?: string) {
    this.lobbyService.joinGameLobby(this.lobby._id, password).subscribe(data => {
      this.lobby = data;
      this.isInLobby = true;
    });
  }

  leaveLobby () {
    this.lobbyService.leaveGameLobby(this.lobby._id).subscribe(data => {
      this.lobby = data;
      this.isInLobby = false;
    });
  }

  startGame () {
    this.lobbyService.startGameLobby(this.lobby._id).subscribe(data => {
      this.toastr.success('Game Started!', 'GLHF!');
      this.router.navigate(['/game', data._id]);
    });
  }

  ngOnInit() {
  }

  saveLobby() {
    this.loading = true;
    this.lobbyService.saveGameLobby(this.lobby._id, this.lobby).subscribe(() => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}
