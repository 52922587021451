import { Pipe, PipeTransform } from '@angular/core';
import {Card} from '../../../models/card.model';

@Pipe({
  name: 'tappableCardFilter'
})
export class TappableCardsFilter implements PipeTransform {

  transform(cards: Card[]): Card[] {
    return cards.filter(c => c.tapOptions && c.tapOptions.length > 0);
  }
}
