import {Component, OnChanges} from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Card} from '../../../../../models/card.model';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss']
})
export class SellComponent extends Decision implements OnChanges {
  selectedCards: Card[];

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnChanges() {
    this.selectedCards = [];
  }

  mapSelectedCards (): string[] {
    return this.selectedCards.map(c => c.name);
  }
}
