import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ceil'
})
export class CeilPipe implements PipeTransform {
  transform(value: number, args: any = null): any {
    if (value) {
      return Math.ceil(value);
    }
    return 0;
  }
}
