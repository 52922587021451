import {Component, OnDestroy, OnInit} from '@angular/core';
import {Game} from '../../models/game.model';
import {ActivatedRoute} from '@angular/router';
import {Player} from '../../models/player.model';
import {GameService} from '../../services/game/game.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewLogModalComponent} from './modals/view-log-modal/view-log-modal.component';
import {CheatModalComponent} from './modals/cheat-modal/cheat-modal.component';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit, OnDestroy {
  game: Game;
  selectedPlayer: Player;
  intervalId: any;

  constructor(private actr: ActivatedRoute,
              private gameService: GameService,
              private modalService: NgbModal,
              private toastr: ToastrService) {
    this.actr.data.subscribe(d => {
      this.game = d['game'];
      this.selectedPlayer = this.game.allPlayers.find(p => p.UserId === this.game.player.UserId);
    });
  }

  ngOnInit() {
    if (this.game.phase !== 'FINAL' && !this.game.currentPlayerDecision) {
      this.intervalId = setInterval(this.checkRefresh.bind(this), 3500);
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  submitDecision(data) {
    this.gameService.submitDecision(this.game._id, data).subscribe(() => {
      this.game.refreshCounter++;
      this.toastr.success('Decision accepted');
      this.refreshGame();
    });
  }

  viewLog () {
    const modal = this.modalService.open(ViewLogModalComponent, {size: 'lg'});
    modal.componentInstance.log = this.game.log;
  }

  checkRefresh () {
    this.gameService.checkRefresh(this.game._id).subscribe(data => {
      if (data.refreshCounter > this.game.refreshCounter) {
        this.game.refreshCounter++;
        this.refreshGame();
      }
    });
  }

  refreshGame () {
    this.gameService.getGame(this.game._id).subscribe((game) => {
      this.game = game;
      if (this.selectedPlayer && this.selectedPlayer.UserId) {
        this.selectedPlayer = this.game.allPlayers.find(p => p.UserId === this.selectedPlayer.UserId);
      } else {
        this.selectedPlayer = this.game.allPlayers.find(p => p.UserId === this.game.player.UserId);
      }
      if (!this.intervalId && this.game.phase !== 'FINAL' && !this.game.currentPlayerDecision) {
        this.intervalId = setInterval(this.checkRefresh.bind(this), 3500);
      }
      if (this.intervalId && (game.phase === 'FINAL' || game.currentPlayerDecision)) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    });
  }


  cheat() {
    this.gameService.getCheats(this.game._id).subscribe(cheats => {
      const modal = this.modalService.open(CheatModalComponent, {size: 'lg'});
      modal.componentInstance.state = this.game;
      modal.componentInstance.cards = cheats.cards;
      modal.result.then((result) => {
        this.gameService.submitCheat(this.game._id, result).subscribe(() => {
          this.refreshGame();
        });
      });
    });
  }
}
