import {Component, OnChanges} from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AwardMilestoneModalComponent} from '../../../modals/award-milestone-modal/award-milestone-modal.component';

@Component({
  selector: 'app-main-action',
  templateUrl: './main-action.component.html',
  styleUrls: ['./main-action.component.scss']
})
export class MainActionComponent extends Decision implements OnChanges {
  autoPass: boolean;
  allPlayersPassed: boolean;

  constructor(modalService: NgbModal) {
    super(modalService);
  }

  ngOnChanges () {
    this.allPlayersPassed = this.game.allPlayers.filter(p => !p.passedThisGeneration).length <= 1;
  }

  submitDecision(ActionId: string, ActionData: any, UIData?: any) {
    if (this.autoPass || (ActionId === 'PASS' && this.allPlayersPassed)) {
      ActionData.autoPass = true;
    }
    super.submitDecision(ActionId, ActionData, UIData);
  }

  awardOrMilestone(type: string) {
    const modal = this.modalService.open(AwardMilestoneModalComponent);
    modal.componentInstance.type = type;
    modal.componentInstance.game = this.game;
    modal.result.then((selection) => {
      const actiondata = {} as any;
      if (type === 'award') {
        actiondata.award = selection.name;
      } else {
        actiondata.milestone = selection.name;
      }
      this.submitDecision((type === 'award' ? 'FUND_AWARD' : 'CLAIM_MILESTONE'), actiondata, {selection: selection});
    });
  }
}
