import {Component, Input, OnInit} from '@angular/core';
import {Tile} from '../../../../../models/tile.model';
import {Game} from '../../../../../models/game.model';

@Component({
  selector: 'app-place-tile',
  templateUrl: './place-tile.component.html',
  styleUrls: ['./place-tile.component.scss']
})
export class PlaceTileComponent implements OnInit {
  @Input() tile: Tile;
  @Input() game: Game;

  constructor() {
  }

  ngOnInit() {
  }
}
