import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'tileImage'})
export class TileImagePipe implements PipeTransform {
  transform (name: string): string {
    let url = 'https://s3.amazonaws.com/play-tfm-bucket/icons/';
    switch (name) {
      case 'ARTIFICIAL_LAKE':
        url += 'ocean';
        break;
      case 'SOLAR_OCEAN':
        url += 'ocean';
        break;
      case 'CAPITAL':
        url += 'capital';
        break;
      case 'COMMERCIAL_DISTRICT':
        url += 'commecial';
        break;
      case 'ECOLOGICAL_ZONE':
        url += 'nature';
        break;
      case 'FLOOD_OCEAN':
        url += 'ocean';
        break;
      case 'GANYMEDE_COLONY':
        url += 'city';
        break;
      case 'INDUSTRIAL_CENTER':
        url += 'factory';
        break;
      case 'LAND_CLAIM':
        url += 'empty';
        break;
      case 'LAVA_FLOWS':
        url += 'volcano';
        break;
      case 'MINING_AREA':
        url += 'mining';
        break;
      case 'MINING_RIGHTS':
        url += 'mining';
        break;
      case 'MOHOLE_AREA':
        url += 'mohole';
        break;
      case 'NATURAL_PRESERVE':
        url += 'preserve';
        break;
      case 'NOCTIS_CITY':
        url += 'city';
        break;
      case 'NUCLEAR_ZONE':
        url += 'nuclear';
        break;
      case 'OCEAN_GREENERY':
        url += 'greenery';
        break;
      case 'PHOBOS_SPACE_HAVEN':
        url += 'city';
        break;
      case 'RESEARCH_OUTPOST':
        url += 'city';
        break;
      case 'RESTRICTED_AREA':
        url += 'restricted';
        break;
      case 'STANDARD_CITY':
        url += 'city';
        break;
      case 'STANDARD_GREENERY':
        url += 'greenery';
        break;
      case 'STANDARD_OCEAN':
        url += 'ocean';
        break;
      case 'URBANIZED_AREA':
        url += 'city';
        break;
      case 'MAXWELL_BASE':
        url += 'city';
        break;
      case 'STRATOPOLIS':
        url += 'city';
        break;
      case 'DAWN_CITY':
        url += 'city';
        break;
      case 'LUNA_METROPOLIS':
        url += 'city';
        break;
    }
    url += '.jpg';
    return url;
  }
}
