import {Component, Input} from '@angular/core';
import {Card} from '../../../../models/card.model';

@Component({
  selector: 'app-card-selector',
  templateUrl: './card-selector.component.html',
  styleUrls: ['./card-selector.component.scss']
})
export class CardSelectorComponent {
  @Input() cards: Card[];
  @Input() selectedCards: Card[];

  constructor() { }

  toggleCard(card: Card) {
    const i = this.cardIndex(card);
    if (i >= 0) {
      this.selectedCards.splice(i, 1);
    } else {
      this.selectedCards.push(card);
    }
  }

  cardIndex(card: Card): number {
    return this.selectedCards.map(c => c.name).indexOf(card.name);
  }
}
