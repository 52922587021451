import { Pipe, PipeTransform } from '@angular/core';
import {Game} from '../../../models/game.model';
import {Player} from '../../../models/player.model';

@Pipe({
  name: 'gameWinner'
})
export class GameWinnerPipe implements PipeTransform {

  transform(game: Game): Player {
    if (game.allPlayers.length === 1) {
      if (game.temperatureSteps === 19 && game.oxygenSteps === 14 && game.oceansRemaining === 0) {
        return game.player;
      } else {
        return null;
      }
    }
    const sortedPlayers = game.finalScores.sort((a, b) => b.finalScore - a.finalScore);
    return sortedPlayers[0].player;
  }

}
