import {Component, EventEmitter, Input, OnChanges, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Game} from '../../../../models/game.model';

@Component({
  selector: 'app-fund-action',
  templateUrl: './fund-action.component.html',
  styleUrls: ['./fund-action.component.scss']
})
export class FundActionComponent implements OnInit, OnChanges {
  @Input() showHeat = false;
  @Input() showTitanium = false;
  @Input() showSteel = false;
  @Input() showDirigibles = false;
  @Input() game: Game;
  @Input() ActionId: string;
  @Input() ActionData: any;
  @Input() UIData: any;

  titanium: number;
  steel: number;
  heat: number;
  dirigibles: number;
  heatOptions: any;
  steelOptions: any;
  titaniumOptions: any;
  dirigibleOptions: any;

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  constructor(public modal: NgbActiveModal) {
    // this.setVals();
  }

  ngOnInit() {
    this.setVals();
  }

  ngOnChanges () {
    this.setVals();
  }

  setVals () {
    this.titanium = 0;
    this.steel = 0;
    this.heat = 0;
    this.dirigibles = 0;
    this.steelOptions = {
      floor: 0,
      ceil: this.game.player.resources.steel,
      showTicksValues: true
    };
    this.titaniumOptions = {
      floor: 0,
      ceil: this.game.player.resources.titanium,
      showTicksValues: true
    };
    this.heatOptions = {
      floor: 0,
      ceil: this.game.player.resources.heat,
      showTicksValues: true
    };
    let diriMax = 0;
    if (this.showDirigibles) {
      const dirigibles = this.game.player.cardsOnBoard.find(c => c.name === 'Dirigibles');
      diriMax = dirigibles.counters;
    }
    this.dirigibleOptions = {
      floor: 0,
      ceil: diriMax,
      showTicksValues: true
    };
    this.manualRefresh.emit();
  }
}
