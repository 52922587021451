import {EventEmitter, Input, Output} from '@angular/core';
import {Game} from '../../../models/game.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDecisionComponent} from '../modals/confirm-decision/confirm-decision.component';
import {FundActionComponent} from '../modals/fund-action/fund-action.component';

export abstract class Decision {
  @Input() game: Game;
  @Output() decided = new EventEmitter<any>();

  protected constructor(public modalService: NgbModal) {

  }

  submitDecision(ActionId: string, ActionData: any, UIData?: any) {
    const heatPayment = this.couldPayHeat(ActionId, ActionData, UIData);
    const steelPayment = this.couldPaySteel(ActionId, ActionData, UIData);
    const titaniumPayment = this.couldPayTitanium(ActionId, ActionData, UIData);
    const dirigiblesPayment = this.couldUseDirigibles(ActionId, ActionData, UIData);
    if (heatPayment || steelPayment || titaniumPayment || dirigiblesPayment) {
      this.spawnFundModal(ActionId, ActionData, heatPayment, steelPayment, titaniumPayment, dirigiblesPayment, UIData);
    } else {
      this.spawnConfirmationModal(ActionId, ActionData, UIData);
    }
  }

  spawnFundModal(ActionId: string, ActionData: any, canUseHeat: boolean, canUseSteel: boolean, canUseTitanium: boolean, canUseDirigibles: boolean, UIData: any) {
    const modal = this.modalService.open(FundActionComponent);
    modal.componentInstance.ActionId = ActionId;
    modal.componentInstance.ActionData = ActionData;
    modal.componentInstance.UIData = UIData;
    modal.componentInstance.game = this.game;
    modal.componentInstance.showHeat = canUseHeat;
    modal.componentInstance.showSteel = canUseSteel;
    modal.componentInstance.showTitanium = canUseTitanium;
    modal.componentInstance.showDirigibles = canUseDirigibles;
    modal.result.then((result) => {
      ActionData.heat = result.heat;
      ActionData.steel = result.steel;
      ActionData.titanium = result.titanium;
      ActionData.dirigibles = result.dirigibles;
      this.spawnConfirmationModal(ActionId, ActionData, UIData);
    });
  }

  spawnConfirmationModal(ActionId: string, ActionData: any, UIData?: any) {
    const modal = this.modalService.open(ConfirmDecisionComponent, {size: 'lg'});
    modal.componentInstance.ActionId = ActionId;
    modal.componentInstance.ActionData = ActionData;
    modal.componentInstance.game = this.game;
    if (UIData) {
      modal.componentInstance.UIData = UIData;
    }
    modal.result.then(() => {
      this.decided.emit({
        DecisionId: this.game.currentPlayerDecision,
        ActionId: ActionId,
        ActionData: ActionData || {}
      });
    });
  }

  couldPaySteel (ActionId: string, ActionData: any, UIData?: any): boolean {
    if (this.game.player.resources.steel === 0) {
      return false;
    }
    if (ActionId === 'PLAY_CARD') {
      return UIData.card.tags.indexOf('BUILDER') >= 0;
    }
    if (ActionId === 'TAP_CARD' && UIData.tapData.canUseSteel) {
      return true;
    }
    return false;
  }

  couldPayTitanium (ActionId: string, ActionData: any, UIData?: any): boolean {
    if (this.game.player.resources.titanium === 0) {
      return false;
    }
    if (ActionId === 'PLAY_CARD') {
      return UIData.card.tags.indexOf('SPACE') >= 0;
    }
    if (ActionId === 'TAP_CARD' && UIData.tapData.canUseTitanium) {
      return true;
    }
    return false;
  }

  couldUseDirigibles (ActionId: string, ActionData: any, UIData?: any): boolean {
    const dirigibles = this.game.player.cardsOnBoard.find(c => c.name === 'Dirigibles');
    if (!dirigibles) {
      return false;
    }
    if (dirigibles.counters === 0) {
      return false;
    }
    if (ActionId === 'PLAY_CARD') {
      return UIData.card.tags.indexOf('VENUS') >= 0;
    }
    return false;
  }

  couldPayHeat (ActionId: string, ActionData: any, UIData?: any): boolean {
    if (!this.game.player.canUseHeatForMoney || this.game.player.resources.heat === 0) {
      return false;
    }
    if (ActionId === 'PLAY_CARD' || ActionId === 'STANDARD_POWER' || ActionId === 'STANDARD_OCEAN' || ActionId === 'STANDARD_TEMP' ||
      ActionId === 'STANDARD_GREENERY' || ActionId === 'STANDARD_CITY' || ActionId === 'PURCHASE_CARDS' || ActionId === 'STANDARD_VENUS' ||
      ActionId === 'CHOOSE_PURCHASE_CARDS' || ActionId === 'CLAIM_MILESTONE' || ActionId === 'FUND_AWARD') {
      return true;
    }
    if (ActionId === 'TAP_CARD' && UIData.tapData.costsMoney) {
      return true;
    }
    if (ActionId === 'SELECT_DECISION' && UIData.option.costsMoney) {
      return true;
    }
    return false;
  }
}
