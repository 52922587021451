import { Component, OnInit } from '@angular/core';
import {Decision} from '../../decision.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-target-card',
  templateUrl: './target-card.component.html',
  styleUrls: ['./target-card.component.scss']
})
export class TargetCardComponent extends Decision implements OnInit {

  constructor(public modal: NgbModal) {
    super(modal);
  }

  ngOnInit() {
  }

}
