import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component';
import { BrowseComponent } from './pages/browse/browse.component';
import { LobbyComponent } from './pages/lobby/lobby.component';
import { GameComponent } from './pages/game/game.component';
import {UserService} from './services/user/user.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './utilities/interceptors/auth.interceptor';
import {ErrorResponseInterceptor} from './utilities/interceptors/game-error.interceptor';
import {UserLoggedInGuard} from './utilities/guards/user-logged-in.guard';
import {UserNotLoggedInGuard} from './utilities/guards/user-not-logged-in.guard';
import {FormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowseService} from './services/browse/browse.service';
import {GameService} from './services/game/game.service';
import {LobbyService} from './services/lobby/lobby.service';
import {GetActiveGamesResolver} from './utilities/resolvers/get-active-games.resolver';
import {GetGameLobbiesResolver} from './utilities/resolvers/get-game-lobbies.resolver';
import { NewLobbyModalComponent } from './pages/browse/new-lobby-modal/new-lobby-modal.component';
import {GetLobbyResolver} from './utilities/resolvers/get-lobby.resolver';
import {GetGameResolver} from './utilities/resolvers/get-game.resolver';
import {GameBoardComponent} from './pages/game/page-components/game-board/game-board.component';
import { TileComponent } from './pages/game/page-components/game-board/tile/tile.component';
import { StartingHandComponent } from './pages/game/decisions/initial/starting-hand/starting-hand.component';
import {CardComponent} from './pages/game/shared/card/card.component';
import {TilesNotOnMarsPipe, TilesOnMarsPipe} from './pages/game/pipes/tiles-on-mars.pipe';
import { CardBooleanFilterPipe } from './pages/game/pipes/card-boolean-filter.pipe';
import {CardImagePipe} from './pages/game/pipes/card-image.pipe';
import { ConfirmDecisionComponent } from './pages/game/modals/confirm-decision/confirm-decision.component';
import { MainActionComponent } from './pages/game/decisions/core/main-action/main-action.component';
import { PlayerHandComponent } from './pages/game/shared/player-hand/player-hand.component';
import {TappableCardsFilter} from './pages/game/pipes/tappable-card-filter.pipe';
import { GameStatusComponent } from './pages/game/page-components/game-status/game-status.component';
import { DraftCardsComponent } from './pages/game/decisions/draft/draft-cards/draft-cards.component';
import { CardSelectorComponent } from './pages/game/shared/card-selector/card-selector.component';
import { PlaceTileComponent } from './pages/game/decisions/core/place-tile/place-tile.component';
import { FundActionComponent } from './pages/game/modals/fund-action/fund-action.component';
import {Ng5SliderModule} from 'ng5-slider';
import { ViewLogModalComponent } from './pages/game/modals/view-log-modal/view-log-modal.component';
import { BugReportModalComponent } from './pages/game/modals/bug-report-modal/bug-report-modal.component';
import {TileImagePipe} from './pages/game/pipes/tile-image.pipe';
import { AwardMilestoneModalComponent } from './pages/game/modals/award-milestone-modal/award-milestone-modal.component';
import {CardFieldValueFilterPipe} from './pages/game/pipes/card-field-value.pipe';
import { SellComponent } from './pages/game/decisions/core/sell/sell.component';
import { ChooseCardEffectComponent } from './pages/game/decisions/core/choose-card-effect/choose-card-effect.component';
import { ChooseAttackTargetComponent } from './pages/game/decisions/core/choose-attack-target/choose-attack-target.component';
import { TargetCardComponent } from './pages/game/decisions/core/target-card/target-card.component';
import { DiscardCardComponent } from './pages/game/decisions/core/discard-card/discard-card.component';
import { NavbarComponent } from './page-components/navbar/navbar.component';
import { VerifyComponent } from './pages/verify/verify.component';
import {NgxLoadingModule} from 'ngx-loading';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { LobbyPasswordModalComponent } from './pages/lobby/lobby-password-modal/lobby-password-modal.component';
import {CeilPipe} from './pages/game/pipes/ceil-pipe';
import {FloorPipe} from './pages/game/pipes/floor-pipe';
import {PlayerSummaryComponent} from './pages/game/page-components/player-summary/player-summary.component';
import { CurrentPlayerBoardComponent } from './pages/game/page-components/current-player-board/current-player-board.component';
import { CurrentPlayerHandComponent } from './pages/game/page-components/current-player-hand/current-player-hand.component';
import {TapCardModalComponent} from './pages/game/page-components/current-player-board/tap-card-modal/tap-card-modal.component';
import { CheatModalComponent } from './pages/game/modals/cheat-modal/cheat-modal.component';
import { TurnOrderPipePipe } from './pages/game/pipes/turn-order-pipe.pipe';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {TileNamePipe, TileRequirementsPipe} from './pages/game/pipes/tile-name.pipe';
import { PurchaseCardDecisionComponent } from './pages/game/decisions/core/purchase-card-decision/purchase-card-decision.component';
import { ProfileComponent } from './pages/profile/profile.component';
import {GetCompletedGamesResolver} from './utilities/resolvers/get-completed-games.resolver';
import { GameEndSummaryComponent } from './pages/game/page-components/game-end-summary/game-end-summary.component';
import { GameWinnerPipe } from './pages/game/pipes/game-winner.pipe';
import {Angulartics2Module} from 'angulartics2';
import { TilePlacementDecisionComponent } from './pages/game/decisions/core/tile-placement-decision/tile-placement-decision.component';
import { YourTurnPipePipe } from './pages/browse/your-turn-pipe.pipe';
import { ScoreboardComponent } from './pages/scoreboard/scoreboard.component';
import { WeeklyScoreboardResolver } from './utilities/resolvers/weekly-scoreboard.resolver';
import { SolarComponent } from './pages/game/decisions/solar/solar/solar.component';
import { VironTapComponent } from './pages/game/decisions/core/viron-tap/viron-tap.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    BrowseComponent,
    LobbyComponent,
    GameComponent,
    NewLobbyModalComponent,
    GameBoardComponent,
    TileComponent,
    TilesOnMarsPipe,
    TilesNotOnMarsPipe,
    StartingHandComponent,
    CardComponent,
    CardBooleanFilterPipe,
    TileImagePipe,
    CardImagePipe,
    ConfirmDecisionComponent,
    MainActionComponent,
    PlayerHandComponent,
    TappableCardsFilter,
    GameStatusComponent,
    DraftCardsComponent,
    CardSelectorComponent,
    PlaceTileComponent,
    TapCardModalComponent,
    FundActionComponent,
    ViewLogModalComponent,
    BugReportModalComponent,
    AwardMilestoneModalComponent,
    CardFieldValueFilterPipe,
    SellComponent,
    ChooseCardEffectComponent,
    ChooseAttackTargetComponent,
    TargetCardComponent,
    DiscardCardComponent,
    NavbarComponent,
    VerifyComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    LobbyPasswordModalComponent,
    CeilPipe,
    FloorPipe,
    PlayerSummaryComponent,
    CurrentPlayerBoardComponent,
    CurrentPlayerHandComponent,
    CheatModalComponent,
    TurnOrderPipePipe,
    TileNamePipe,
    TileRequirementsPipe,
    PurchaseCardDecisionComponent,
    ProfileComponent,
    GameEndSummaryComponent,
    GameWinnerPipe,
    TilePlacementDecisionComponent,
    YourTurnPipePipe,
    ScoreboardComponent,
    SolarComponent,
    VironTapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    UiSwitchModule,
    HttpClientModule,
    NgbModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    Angulartics2Module.forRoot(),
    NgxLoadingModule.forRoot({})
  ],
  providers: [
    UserService,
    BrowseService,
    LobbyService,
    GameService,
    UserLoggedInGuard,
    UserNotLoggedInGuard,
    GetActiveGamesResolver,
    GetLobbyResolver,
    GetGameResolver,
    GetCompletedGamesResolver,
    WeeklyScoreboardResolver,
    GetGameLobbiesResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NewLobbyModalComponent,
    ConfirmDecisionComponent,
    TapCardModalComponent,
    FundActionComponent,
    ViewLogModalComponent,
    BugReportModalComponent,
    AwardMilestoneModalComponent,
    LobbyPasswordModalComponent,
    CheatModalComponent
  ]
})
export class AppModule { }
