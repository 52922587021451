import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lobby-password-modal',
  templateUrl: './lobby-password-modal.component.html',
  styleUrls: ['./lobby-password-modal.component.scss']
})
export class LobbyPasswordModalComponent implements OnInit {
  password: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
